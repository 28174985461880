import React from 'react';
import ReactDOM from 'react-dom';

// Style dependencies
import './css/index.css';

//Media Queries
import './css/mobile.css';
import './css/tablets.css';
import './css/desktop.css';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

// Components
import App from './components/App';

// Utilities
import registerServiceWorker from './util/registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
